import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  ContactForm,
  FoodMenu,
} from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";
import "../style/catering.css";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Catering"}
              tagline={""}
              images={[
                "https://fisherman.gumlet.io/public/dusalsmilltownnj/Dusals_Catering_Food_catering_15.jpeg",
              ]}
              ctas={[
                {
                  link: "https://fisherman.gumlet.io/public/dusalsmilltownnj/Dusals_Party_Menu.pdf",
                  internal: false,
                  text: "On Premise Menu",
                },
                {
                  link: "https://fisherman.gumlet.io/public/dusalsmilltownnj/catering1.pdf",
                  internal: false,
                  text: "Off Premise Menu",
                },
                {
                  link: "https://fisherman.gumlet.io/public/dusalsmilltownnj/jpg2pdf.pdf",
                  text: "Repast Luncheon",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column computer={16} mobile={16}>
              <h2>Catering For Many Occasions</h2>
              <Grid
                stackable={true}
                columns={"equal"}
                verticalAlign={"middle"}
                textAlign={"center"}
              >
                <Grid.Row stretched={true}>
                  <Grid.Column>
                    <Image
                      centered={true}
                      size={"Large"}
                      src={
                        "https://fisherman.gumlet.io/public/dusalsmilltownnj/1.jpg?width=500"
                      }
                    />
                    <h3>Social Occasions</h3>
                    <p>
                      From birthdays, weddings, graduations and everything in
                      between, make the occasion memorable with authentic
                      Italian meals. Allow Catering By Dusal's create a
                      delicious meal for your guests while you enjoy good
                      company.
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      centered={true}
                      size={"Large"}
                      src={
                        "https://fisherman.gumlet.io/public/dusalsmilltownnj/2.jpg?width=500"
                      }
                    />
                    <h3>Corporate Events</h3>
                    <p>
                      With Meetings, celebrations, fundraisers and more, Dusal's
                      specializes in catering for large and small groups with
                      convenient packages at great prices and menu selections
                      that everyone will love.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
              <h4>
                Dusal’s Italian Restaurant & Pizzeria Gets Your Food Catering
                Done Right!
              </h4>
              <p>
                Our catering menu at Dusal’s Restaurant & Pizzeria consists of
                all different types of food you could ever need. We provide
                items such as appetizers, salads, platters, subs, and deserts.
                Each choice is capable of serving any amount of people with
                different sizing options available as well. We guarantee your
                guests will be satisfied and well fed at your event!
              </p>
              <p>
                At Dusal’s Restaurant & Pizzeria, we respect how important your
                events are. That is why we work with you to create a precise
                catering plan. With us, we take into consideration anything that
                may be concerning for you when serving food for others. For
                example, we go over things like allergies, special diets, the
                type of event, and more. We guarantee quality customer service
                no matter how big or small your event is. We have experience
                catering for intimate birthday parties for children consisting
                of only 5 or 10 guests. On the other hand, we have also provided
                catering for large corporate events in which we provided meals
                for hundreds of guests. No matter what the event is, you have
                our guarantee that we can handle it all!
              </p>
              <p>
                Have any concerns about pricing? Don’t worry! We are always
                willing to work with you to alter your order to better suit your
                needs and budget to provide you with the absolute best catering
                experience. Dusal’s Restaurant & Pizzeria always aims to leave
                customers satisfied no matter where they are. Whether they are
                eating our food in house or at your special event, we love to
                leave smiles on faces!
              </p>
            </Grid.Column>
            <Grid.Column width={10}>
              <iframe
                className={"catering-video"}
                width={"650"}
                height={"420"}
                src={"https://www.youtube.com/embed/KiUxhkE7MiA"}
                title={"YouTube video player"}
                frameborder={"0"}
                allow={
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                }
                allowfullscreen={false}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={10}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  eventDate,
                  phone,
                  startTime,
                  endTime,
                  guest,
                  occasion,
                  address,
                  city,
                  state,
                  zip,
                  premises,
                }) =>
                  `Someone has reached out to you via your website catering form: Name: ${name}, Email: ${email}, Phone: ${phone}, Event Date: ${eventDate}, Start Time: ${startTime}, End Time: ${endTime}, Guesses: ${guest}, Address: ${address} ${city}, ${state} ${zip},  Occasion: ${occasion}, Premises: ${premises}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "phone",
                    displayLabel: "Phone Number",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    displayLabel: "Email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "eventDate",
                    displayLabel: "Event Date",
                    placeholder: "Event Date",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "startTime",
                    displayLabel: "Start Time",
                    placeholder: "Start Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "endTime",
                    displayLabel: "End Time",
                    placeholder: "End Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "address",
                    displayLabel: "Address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "city",
                    displayLabel: "City",
                    placeholder: "City",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "state",
                    displayLabel: "State",
                    placeholder: "State",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "zip",
                    displayLabel: "Zip Code",
                    placeholder: "Zip Code",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "guest",
                    displayLabel: "Number Of Guests",
                    placeholder: "Number Of Guests",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "occasion",
                    displayLabel: "Occasion",
                    placeholder: "Occasion",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "premises",
                    displayLabel: "On or Off Premises",
                    placeholder: "On or Off Premises",
                    type: "select",
                    options: [
                      { label: "On-Premise", value: "On-Premise" },
                      { label: "Off-Premise", value: "Off-Premise" },
                    ],
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
            <Grid.Column className={"catering-menu-container"} width={16}>
              <h2>Catering Menu</h2>
              <FoodMenu
                header={""}
                centerNavigation={true}
                categoryDisplayVariant={"HorizontalCardRow"}
                itemDisplayVariant={"HorizontalCard"}
                menu={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                  modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                  modifiers: allFishermanBusinessMenuModifier.nodes,
                }}
                business={{
                  id: fishermanBusiness._id,
                  type: fishermanBusiness.type,
                }}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Catering" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
    allFishermanBusinessMenuSchedule(
      filter: { name: { eq: "Catering Menu" } }
    ) {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
    allFishermanBusinessMenuModifierSet {
      nodes {
        _id
        minAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier {
      nodes {
        _id
        name
        price
      }
    }
  }
`;
